/**
 * Keestash
 *
 * Copyright (C) <2022> <Dogan Ucar>
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published
 * by the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <https://www.gnu.org/licenses/>.
 */

import json from './../../Common/Env/index';

const PASSWORD_MANAGER_CHANGE_STATE = '/password_manager/node/pwned/change_state';
const PASSWORD_MANAGER_BREACHES_ACTIVE = '/password_manager/node/pwned/is_active';

export const ROUTES = {
    getLdapConfig: () => {
        return json.backend.path + "/ldap/get/active";
    },
    requestCancellation: () => {
        return json.backend.path + "/payment/webhook/cancel";
    },
    addOrganization: () => {
        return json.backend.path + "/organizations/add";
    },
    deactivate: () => {
        return json.backend.path + "/ldap/deactivate";
    },
    remove: () => {
        return json.backend.path + "/ldap/remove";
    },
    changeState: () => {
        return json.backend.path + PASSWORD_MANAGER_CHANGE_STATE;
    },

    isBreachesActive: () => {
        return json.backend.path + PASSWORD_MANAGER_BREACHES_ACTIVE;
    },

}
