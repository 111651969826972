<template>
  <View :has-search="true">
    <template v-slot:topNavigationContent>
      <button class="k-btn btn-primary btn-sm buttonBase" @click="onAddPassword">
        {{ $t('password.actionBar.password.button.title') }}
      </button>
      <button class="k-btn btn-secondary btn-sm buttonBase text-white" @click="onAddFolder">
        {{ $t('password.actionBar.folder.button.title') }}
      </button>
    </template>
    <template v-slot:view-content>
      <LoadingPasswords
          v-if="showLoadingScreen()"
      ></LoadingPasswords>
      <div id="password-wrapper" v-else>

        <div class="container-fluid mt-3 mb-3">
          <div class="text-primary">{{ $t('password.title') }}</div>
          <div class="text-secondary">
            <small>{{ $t('password.subTitle') }}</small>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row justify-content-start">

            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="text-secondary breadcrumb-item clickable"
                    @drop="drop($event, breadCrumb.id)"
                    @dragover="dragover($event)"
                    @dragleave="dragleave($event)"
                    v-for="(breadCrumb,index) in this.state.breadCrumb.value"><a
                    @click="loadEdges(breadCrumb.id)">{{ breadCrumb.name }}</a></li>
              </ol>
            </nav>

          </div>
        </div>
        <div class="container-fluid">
          <EmptyPasswords
              v-if="showEmptyScreen()"
              class="mb-4"
          ></EmptyPasswords>
          <table class="table" id="password-manager-table" v-else>
            <thead class="d-none d-lg-header-group d-xl-header-group d-xxl-header-group">
            <tr>
              <th class="text-secondary"></th>
              <th class="text-secondary" scope="col">{{ $t('password.table.head.name') }}</th>
              <th class="text-secondary text-center" scope="col">
                {{ $t('password.table.head.organization') }}
              </th>
              <th class="text-secondary text-center" scope="col">{{
                  $t('password.table.head.created')
                }}
              </th>
              <th class="text-secondary text-center" scope="col">{{
                  $t('password.table.head.updated')
                }}
              </th>
              <th class="text-secondary text-center" scope="col"></th>
              <!--              <th class="text-secondary text-center" scope="col">{{-->
              <!--                  $t('password.table.head.entropy')-->
              <!--                }}-->
              <!--              </th>-->
              <!--              <th class="text-secondary text-center" scope="col">{{-->
              <!--                  $t('password.table.head.health')-->
              <!--                }}-->
              <!--              </th>-->
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(edge) in state.node.value.edges.content"
                :key="edge.id"
                class="align-middle password-row"
                @click="onEdgeClick(edge)"
                @dragstart="startDragging($event, edge) "
                @dragend="endDragging($event) "
                @drop="edge.node.type === 'folder' ? drop($event, edge.node.id) : null"
                @dragover="edge.node.type === 'folder' ? dragover($event) : null"
                @dragleave="edge.node.type === 'folder' ? dragleave($event) : null"
                draggable="true"
            >
              <td class="text-primary pwm-table-cell pwm-table-cell pe-0">
                <img src="./../../assets/img/password/folder-fill.svg" class="icon-image"
                     :alt="edge.node.name"
                     v-if="edge.node.type === 'folder'"
                >
                <img src="./../../assets/img/left-nav/password.svg" class="icon-image"
                     :alt="edge.node.name"
                     v-else
                >
              </td>
              <td class="text-start ps-0">
                <div class="text-primary"> {{ edge.node.name }}</div>
                <div class="text-secondary font-weight-500">
                  <small v-if="edge.node.type === 'credential'">
                    {{ keestashEncryptionService.decrypt(edge.node.username) }} </small>
                  <small v-else>
                    {{ edge.node.edge_size }} entries
                  </small>
                </div>
              </td>
              <td class="text-center d-none d-lg-table-cell pwm-table-cell">
                <img class="clickable" src="./../../assets/img/common/x-lg-white.svg"
                     v-if="edge.node.organization === null"
                >
                <img class="clickable" src="./../../assets/img/common/check-lg.svg"
                     v-else
                >
              </td>
              <td class="text-center d-none d-lg-table-cell font-weight-400 pwm-table-cell">
                {{ formatDate(edge.node.create_ts.date) }}
              </td>
              <td class="text-center d-none d-lg-table-cell font-weight-400 pwm-table-cell">
                {{
                  edge.node.update_ts !== null ? formatDate(edge.node.update_ts.date) : ''
                }}
              </td>
              <!--              <td class="text-center d-none d-lg-table-cell">-->

              <!--                <img :src="getEntropy(edge.node.entropy.plain)"-->
              <!--                     v-if="edge.node.type === 'credential'">-->

              <!--              </td>-->
              <!--              <td class="text-center d-none d-lg-table-cell" :title="getHealthDescription(edge.node.id)">-->
              <!--                <img :src="getHealthIcon(edge.node.id)"-->
              <!--                     :alt="getHealthDescription(edge.node.id)"-->
              <!--                     v-if="edge.node.type === 'credential'">-->

              <!--              </td>-->
              <td class="text-center d-none d-lg-table-cell pwm-table-cell">
                <img class="clickable" src="./../../assets/img/password/three-dots.svg"
                     @click="onThreeDotsClick($event, edge, this.$refs.menu)"
                ></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="modal" tabindex="-1" aria-labelledby=""
           aria-hidden="true" ref="deleteModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content k-modal-content">
            <div class="modal-header d-flex justify-content-between">
              <img src="./../../assets/img/left-nav/password-white.svg"
                   alt="add password"
              >
              <h5 class="modal-title">
                {{ $t("password.modal.node.delete.title") }}
              </h5>
              <div @click="state.modal.value.node.delete.instance.hide()" class="clickable">
                <img src="./../../assets/img/common/x-lg-white.svg"
                     alt="close dialog"
                >
              </div>
            </div>
            <div class="modal-body">
              {{ $t("password.modal.node.delete.body") }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="deleteNode">
                {{ $t("password.modal.node.delete.actionButton") }}
              </button>
              <button type="button" class="btn btn-secondary text-white"
                      @click="state.modal.value.node.delete.instance.hide()">
                {{ $t("password.modal.node.delete.closeButton") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" tabindex="-1" aria-labelledby=""
           aria-hidden="true" ref="regularShareModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content k-modal-content">
            <div class="modal-header d-flex justify-content-between">
              <img src="./../../assets/img/left-nav/password-white.svg"
                   alt="add password"
              >
              <h5 class="modal-title">
                {{ $t("password.modal.node.share.regular.title") }}
              </h5>
              <div @click="state.modal.value.node.share.regular.instance.hide()" class="clickable">
                <img src="./../../assets/img/common/x-lg-white.svg"
                     alt="close dialog"
                >
              </div>
            </div>
            <div class="modal-body">
              {{ $t("password.modal.node.share.regular.body") }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="shareNode">
                {{ $t("password.modal.node.share.regular.actionButton") }}
              </button>
              <button type="button" class="btn btn-secondary text-white"
                      @click="state.modal.value.node.share.regular.instance.hide()">
                {{ $t("password.modal.node.share.regular.closeButton") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" tabindex="-1" aria-labelledby=""
           aria-hidden="true" ref="renameModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content k-modal-content">
            <div class="modal-header d-flex justify-content-between">
              <img src="./../../assets/img/left-nav/password-white.svg"
                   alt="add password"
              >
              <h5 class="modal-title">
                {{ $t("password.modal.folder.rename.title") }}
              </h5>
              <div @click="state.modal.value.folder.rename.instance.hide()" class="clickable">
                <img src="./../../assets/img/common/x-lg-white.svg"
                     alt="close dialog"
                >
              </div>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col mb-4">
                    <label for="folder-rename-placeholder" class="modal-label mb-1">
                      <small>{{ $t("password.modal.folder.rename.name.title") }}</small>
                    </label>
                    <input type="text" id="folder-rename-placeholder" class="k-form-control modal-input"
                           :placeholder="$t('password.modal.folder.rename.name.placeholder')"
                           v-model="state.modal.value.folder.rename.name">
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="renameNode">
                {{ $t("password.modal.folder.rename.actionButton") }}
              </button>
              <button type="button" class="btn btn-secondary text-white"
                      @click="state.modal.value.folder.rename.instance.hide()">
                {{ $t("password.modal.folder.rename.closeButton") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" aria-labelledby=""
           aria-hidden="true" ref="redirectModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content k-modal-content">
            <div class="modal-header d-flex justify-content-between">
              <img src="./../../assets/img/left-nav/password-white.svg"
                   alt="add password"
              >
              <h5 class="modal-title">
                {{ $t("password.modal.password.update.redirect.title") }}
              </h5>
              <div @click="state.modal.value.password.update.redirect.instance.hide()" class="clickable">
                <img src="./../../assets/img/common/x-lg-white.svg"
                     alt="close dialog"
                >
              </div>
            </div>
            <div class="modal-body">
              {{ $t("password.modal.password.update.redirect.body") }}
              {{ state.modal.value.password.update.redirect.url }}
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" @click="redirect">
                {{ $t("password.modal.password.update.redirect.action") }}
              </button>
              <button type="button" class="btn btn-secondary text-white"
                      @click="state.modal.value.password.update.redirect.instance.hide()">
                {{ $t("password.modal.password.update.redirect.close") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" tabindex="-1" aria-labelledby=""
           aria-hidden="true" ref="addModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content k-modal-content">
            <div class="modal-header d-flex justify-content-between">
              <img src="./../../assets/img/left-nav/password-white.svg"
                   alt="add password"
              >
              <h5 class="modal-title">
                {{ $t("password.modal.password.add.title") }}
              </h5>
              <div @click="state.modal.value.password.add.instance.hide()" class="clickable">
                <img src="./../../assets/img/common/x-lg-white.svg"
                     alt="close dialog"
                >
              </div>
            </div>
            <div class="modal-body">
              <div class="alert alert-danger" role="alert"
                   v-for="error in state.modal.value.password.add.errors">
                {{ error }}
              </div>
              <div class="alert alert-success" role="alert"
                   v-for="success in state.modal.value.password.add.success">
                {{ success }}
              </div>

              <form>
                <div class="row">
                  <div class="col mb-4">
                    <label for="pw-add-name" class="modal-label mb-1">
                      <small>{{ $t("password.modal.password.add.form.name.title") }}</small>
                    </label>
                    <input type="text" id="pw-add-name" class="k-form-control-less-bold-500 modal-input"
                           :placeholder="$t('password.modal.password.add.form.name.placeholder')"
                           v-model="state.modal.value.password.add.form.name">
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-4">
                    <label for="pw-add-username" class="modal-label mb-1">
                      <small>{{ $t("password.modal.password.add.form.username.title") }}</small>
                    </label>
                    <input
                        type="text"
                        id="pw-add-username"
                        class="k-form-control-less-bold-500 modal-input"
                        :placeholder="$t('password.modal.password.add.form.username.placeholder')"
                        v-model="state.modal.value.password.add.form.userName"
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-4">
                    <label for="pw-add-pw" class="modal-label mb-1">
                      <small>{{ $t("password.modal.password.add.form.password.title") }}</small>
                    </label>
                    <input
                        :type="state.modal.value.password.add.form.password.focused ? 'text' : 'password'"
                        id="pw-add-pw"
                        class="k-form-control-less-bold-500 modal-input"
                        :placeholder="$t('password.modal.password.add.form.password.placeholder')"
                        v-model="state.modal.value.password.add.form.password.value"
                        @focusin="onFocusNewPassword"
                        @focusout="onFocusNewPassword"
                    >
                    <div class="col mt-1">
                      <Entropy :value="state.modal.value.password.add.form.password.value"></Entropy>
                    </div>

                  </div>
                  <div class="p-0 col-1 justify-content-start align-self-center clickable __margin_bottom__11rem"
                       @click="onPasswordGeneratorAddShown">
                    <img src="./../../assets/img/common/gear-wide-connected.svg" class="icon-image-17rem"
                         alt="add password"
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col mb-4">
                    <label for="pw-add-url" class="modal-label mb-1">
                      <small>{{ $t("password.modal.password.add.form.url.title") }}</small>
                    </label>
                    <input type="text" id="pw-add-url" class="k-form-control-less-bold-500 modal-input"
                           :placeholder="$t('password.modal.password.add.form.url.placeholder')"
                           v-model="state.modal.value.password.add.form.url">
                  </div>
                </div>
                <Transition name="password-generator-add" @after-leave="clearPasswordGeneratorAdd">
                  <div class="row" v-if="state.modal.value.password.add.passwordGenerator.passwordGeneratorShown">
                    <div class="col">
                      <div class="container-fluid p-0">
                        <div class="row">
                          <div class="col">
                            <div class="h5 text-center text-primary">
                              {{ $t('password.modal.password.add.passwordGenerator.title') }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col text-center text-secondary">
                            {{ $t('password.modal.password.add.passwordGenerator.subTitle') }}
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-check form-switch d-flex justify-content-between ps-0">
                              <label
                                  class="form-check-label">{{
                                  $t('password.modal.password.add.passwordGenerator.values.upperCase')
                                }}</label>
                              <input class="form-check-input" type="checkbox"
                                     v-model="state.modal.value.password.add.passwordGenerator.values.upperCase">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-check form-switch d-flex justify-content-between ps-0">
                              <label
                                  class="form-check-label">{{
                                  $t('password.modal.password.add.passwordGenerator.values.lowerCase')
                                }}</label>
                              <input class="form-check-input" type="checkbox"
                                     v-model="state.modal.value.password.add.passwordGenerator.values.lowerCase">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-check form-switch d-flex justify-content-between ps-0">
                              <label
                                  class="form-check-label">{{
                                  $t('password.modal.password.add.passwordGenerator.values.digits')
                                }}</label>
                              <input class="form-check-input" type="checkbox"
                                     v-model="state.modal.value.password.add.passwordGenerator.values.digits">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <div class="form-check form-switch d-flex justify-content-between ps-0">
                              <label
                                  class="form-check-label">!@#$%^*</label>
                              <input class="form-check-input" type="checkbox"
                                     v-model="state.modal.value.password.add.passwordGenerator.values.specialChar">
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col">
                            <input type="range"
                                   v-model="state.modal.value.password.add.passwordGenerator.values.length"
                                   class="form-range">
                          </div>
                        </div>
                        <div class="row">
                          <div class="col text-center">
                            {{ $t('password.modal.password.add.passwordGenerator.values.length') }}:
                            {{ state.modal.value.password.add.passwordGenerator.values.length }}
                          </div>
                        </div>
                        <div class="row"
                             v-if="state.modal.value.password.add.passwordGenerator.generated.password.value!==null">
                          <div class="col text-center text-break bg-light generated-password">
                            {{
                              state.modal.value.password.add.passwordGenerator.generated.password.value
                            }}

                          </div>
                        </div>
                        <div class="row">
                          <div class="d-flex col justify-content-between">
                            <button class="btn btn-primary" @click.prevent="() => generatePassword('add')">
                              {{ $t('password.modal.password.add.passwordGenerator.buttons.generate') }}
                            </button>
                            <button class="btn btn-primary" @click.prevent="() => useGeneratedPassword('add')">
                              {{ $t('password.modal.password.add.passwordGenerator.buttons.use') }}
                            </button>
                            <button class="btn btn-primary" @click.prevent="() => copyPassword('add')">
                              {{ $t('password.modal.password.add.passwordGenerator.buttons.copy') }}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </form>
            </div>
            <div class="modal-footer">
              <div class="spinner-border" role="status" v-if="state.modal.value.password.add.form.interacting"></div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="addMoreInputId"
                       v-model="state.modal.value.password.add.form.addMore">
                <label class="form-check-label" for="addMoreInputId">
                  Add More
                </label>
              </div>
              <button type="button" class="k-btn btn-primary" @click="createPassword"
                      :disabled="state.modal.value.password.add.form.interacting">
                {{ $t("password.modal.password.add.form.addButton") }}
              </button>
              <button type="button" class="k-btn btn-secondary text-white"
                      @click="state.modal.value.password.add.instance.hide()">
                {{ $t("password.modal.password.add.form.closeButton") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal modal-lg" tabindex="-1" aria-labelledby=""
           aria-hidden="true" ref="updateModal">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content k-modal-content">
            <div class="modal-header d-flex justify-content-between">
              <img src="./../../assets/img/left-nav/password-white.svg"
                   alt="update password"
              >
              <h5 class="modal-title">
                {{ $t("password.modal.password.update.title") }}
              </h5>

              <div @click="state.modal.value.password.update.instance.hide()" class="clickable">
                <img src="./../../assets/img/common/x-lg-white.svg"
                     alt="close dialog"
                >
              </div>
            </div>

            <div class="modal-body">

              <div class="container">
                <div class="row d-flex flex-column flex-lg-row">
                  <div
                      class="nav flex-row nav-pills me-3 col col-lg-3 justify-content-between justify-content-lg-start flex-lg-column pb-3"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical">
                    <button class="nav-link active font-weight-400" id="v-pills-home-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-home" type="button" role="tab"
                            aria-controls="v-pills-home"
                            @click.prevent="state.modal.value.password.update.showButtons = true"
                            aria-selected="true">{{ $t('password.modal.password.home.name') }}
                    </button>
                    <button class="nav-link font-weight-400" id="v-pills-additionalData-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-additionalData" type="button" role="tab"
                            aria-controls="v-pills-additionalData"
                            aria-selected="false" @click.prevent="onAdditionalDataClick">
                      {{ $t('password.modal.password.additionalData.name') }}
                    </button>
                    <button class="nav-link font-weight-400" id="v-pills-attachment-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-attachment" type="button" role="tab"
                            aria-controls="v-pills-attachment"
                            aria-selected="false" @click.prevent="onAttachmentClick">
                      {{ $t('password.modal.password.attachment.name') }}
                    </button>
                    <button class="nav-link font-weight-400" id="v-pills-activity-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-activity" type="button" role="tab"
                            aria-controls="v-pills-activity"
                            aria-selected="false" @click.prevent="onActivityClick">
                      {{ $t('password.modal.password.activities.name') }}
                    </button>
                    <button class="nav-link font-weight-400" id="v-pills-share-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-share" type="button" role="tab"
                            aria-controls="v-pills-share"
                            aria-selected="false" @click.prevent="onShareClick">
                      {{ $t('password.modal.password.share.regularShare.name') }}
                    </button>
                  </div>
                  <div class="col">
                    <div class="tab-content" id="v-pills-tabContent">
                      <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                           aria-labelledby="v-pills-home-tab">
                        <div class="alert alert-danger" role="alert"
                             v-for="error in state.modal.value.password.update.errors">
                          {{ error }}
                        </div>
                        <div class="alert alert-success" role="alert"
                             v-for="success in state.modal.value.password.update.success">
                          {{ success }}
                        </div>
                        <div class="form ps-2 pe-2">
                          <form>
                            <div class="row">
                              <div class="col mb-4">
                                <label for="name" class="modal-label mb-1">
                                  <small>{{ $t("password.modal.password.update.form.name.title") }}</small>
                                </label>
                                <input type="text" id="name" class="k-form-control-less-bold-500 modal-input"
                                       :placeholder="$t('password.modal.password.update.form.name.placeholder')"
                                       v-model="state.modal.value.password.update.form.name">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col mb-4">
                                <label for="username" class="modal-label mb-1">
                                  <small>{{ $t("password.modal.password.update.form.username.title") }}</small>
                                </label>
                                <div class="input-group mb-3">
                                  <input type="text" id="username" class="k-form-control-less-bold-500 modal-input"
                                         :placeholder="$t('password.modal.password.update.form.username.placeholder')"
                                         v-model="state.modal.value.password.update.form.userName"
                                  >
                                  <button class="btn btn-light button-border input-group-text clickable" id="copy"
                                          @click.prevent="onUsernameCopy">
                                    <img src="./../../assets/img/common/files.svg"
                                         alt="add password"
                                    >
                                  </button>

                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col mb-4">
                                <PasswordField
                                    :title="$t('password.modal.password.update.form.password.title')"
                                    :placeholder="$t('password.modal.password.update.form.password.placeholder')"
                                    @eyeClick="onEyeClick"
                                    @copyClick="onCopyClick"
                                    @onPasswordChange="setPasswordTimeout"
                                    v-model="state.modal.value.password.update.form.password"
                                ></PasswordField>
                                <Entropy class="mt-1"
                                         :value="state.modal.value.password.update.form.password"></Entropy>
                              </div>
                              <div
                                  class="p-0 col-1 justify-content-start align-self-center clickable __margin_bottom__11rem"
                                  @click="onPasswordGeneratorShown">
                                <img src="./../../assets/img/common/gear-wide-connected.svg" class="icon-image-17rem">
                              </div>
                            </div>
                            <div class="row">
                              <div class="col mb-4">
                                <label for="url" class="modal-label mb-1">
                                  <small>{{ $t("password.modal.password.update.form.url.title") }}</small>
                                </label>
                                <div class="input-group">
                                  <input type="text" id="url" class="k-form-control-less-bold-500 modal-input"
                                         :placeholder="$t('password.modal.password.update.form.url.placeholder')"
                                         v-model="state.modal.value.password.update.form.url">
                                  <div class="input-group-append d-flex" @click.prevent="onOpenLink">
                                    <button class="btn btn-light button-border input-group-text flex-grow-1 clickable"
                                            id="forward">
                                      <img src="./../../assets/img/common/box-arrow-up-right.svg" id="my-icon"
                                           alt="add password"
                                      >
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Transition name="password-generator" @after-leave="clearPasswordGeneratorUpdate">
                              <div class="row"
                                   v-if="state.modal.value.password.update.passwordGenerator.passwordGeneratorShown">
                                <div class="col">
                                  <div class="container-fluid p-0">
                                    <div class="row">
                                      <div class="col">
                                        <div class="h5 text-center text-primary">
                                          {{ $t('password.modal.password.update.passwordGenerator.title') }}
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col text-center text-secondary">
                                        {{ $t('password.modal.password.update.passwordGenerator.subTitle') }}
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col">
                                        <div class="form-check form-switch d-flex justify-content-between ps-0">
                                          <label
                                              class="form-check-label">{{
                                              $t('password.modal.password.update.passwordGenerator.values.upperCase')
                                            }}</label>
                                          <input class="form-check-input" type="checkbox"
                                                 v-model="state.modal.value.password.update.passwordGenerator.values.upperCase">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col">
                                        <div class="form-check form-switch d-flex justify-content-between ps-0">
                                          <label
                                              class="form-check-label">{{
                                              $t('password.modal.password.update.passwordGenerator.values.lowerCase')
                                            }}</label>
                                          <input class="form-check-input" type="checkbox"
                                                 v-model="state.modal.value.password.update.passwordGenerator.values.lowerCase">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col">
                                        <div class="form-check form-switch d-flex justify-content-between ps-0">
                                          <label
                                              class="form-check-label">{{
                                              $t('password.modal.password.update.passwordGenerator.values.digits')
                                            }}</label>
                                          <input class="form-check-input" type="checkbox"
                                                 v-model="state.modal.value.password.update.passwordGenerator.values.digits">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col">
                                        <div class="form-check form-switch d-flex justify-content-between ps-0">
                                          <label
                                              class="form-check-label">!@#$%^*</label>
                                          <input class="form-check-input" type="checkbox"
                                                 v-model="state.modal.value.password.update.passwordGenerator.values.specialChar">
                                        </div>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col">
                                        <input type="range"
                                               v-model="state.modal.value.password.update.passwordGenerator.values.length"
                                               class="form-range">
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col text-center">
                                        {{ $t('password.modal.password.update.passwordGenerator.values.length') }}:
                                        {{ state.modal.value.password.update.passwordGenerator.values.length }}
                                      </div>
                                    </div>
                                    <div class="row"
                                         v-if="state.modal.value.password.update.passwordGenerator.generated.password.value!==null">
                                      <div class="col text-center text-break bg-light generated-password">
                                        {{
                                          state.modal.value.password.update.passwordGenerator.generated.password.value
                                        }}

                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="d-flex col justify-content-between">
                                        <button class="btn btn-primary"
                                                @click.prevent="() => generatePassword('update')">
                                          {{ $t('password.modal.password.update.passwordGenerator.buttons.generate') }}
                                        </button>
                                        <button class="btn btn-primary"
                                                @click.prevent="() => useGeneratedPassword('update')">
                                          {{ $t('password.modal.password.update.passwordGenerator.buttons.use') }}
                                        </button>
                                        <button class="btn btn-primary" @click.prevent="() => copyPassword('update')">
                                          {{ $t('password.modal.password.update.passwordGenerator.buttons.copy') }}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Transition>
                          </form>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="v-pills-additionalData" role="tabpanel"
                           aria-labelledby="v-pills-additionalData-tab">
                        <div class="container">
                          <div class="row border rounded pwm-properties-name-list">
                            <div class="col-3 mt-1 h-100 d-flex flex-column justify-content-center">
                              <Loading
                                  v-if="[0,2].includes(state.modal.value.password.additionalData.retrieve.state)"></Loading>
                              <ul class="list-group list-group-flush h-100 overflow-scroll"
                                  v-else-if="state.modal.value.password.additionalData.retrieve.state===4">
                                <li class="list-group-item m-0 p-0 d-flex flex-row justify-content-between"
                                    v-for="d in state.modal.value.password.additionalData.retrieve.data"
                                    @click.prevent="onAdditionalClick(d.id)"

                                >
                                  <div class="clickable font-weight-600-size-14px">
                                    {{ d.key }}
                                  </div>
                                  <div class="clickable d-flex justify-content-center"
                                       @click.prevent="deleteAdditionalData($event, d.id)"
                                  >
                                    <img src="./../../assets/img/common/x-lg-black.svg"
                                         style="width: 15px"
                                         alt="close dialog"
                                    >
                                  </div>
                                </li>
                              </ul>
                              <div class="d-flex flex-column justify-content-center align-items-center text-center"
                                   v-else>
                                <img src="./../../assets/img/book-open-reader.svg"
                                     alt="note"
                                     class="w-75 h-100"

                                >
                                <div class="font-weight-400-12px">
                                  {{ $t('password.modal.password.additionalData.nothingHereTitle') }}
                                </div>
                              </div>
                            </div>
                            <div class="col-9 border-start h-100">
                              <p class="text-justify h-100 justify-content-center align-items-center d-flex flex-column"
                                 v-if="state.modal.value.password.additionalData.retrieve.retrieved.value === null"
                              >
                                <Loading
                                    v-if="state.modal.value.password.additionalData.retrieve.retrieved.loading"></Loading>
                                <img src="./../../assets/img/list-columns-reverse.svg"
                                     alt="note"
                                     v-if="!state.modal.value.password.additionalData.retrieve.retrieved.loading"
                                     style="width: 15%"
                                >

                              </p>
                              <div class="col d-flex text-justify overflow-scroll h-100 font-weight-600-size-14px"
                                   v-else>
                                {{ state.modal.value.password.additionalData.retrieve.retrieved.value }}
                              </div>
                            </div>

                          </div>
                          <div class="row mt-3">
                            <div class="col p-0">
                              <div class="input-group mb-1">
                                <input type="text" class="k-form-control-less-bold"
                                       :placeholder="$t('password.modal.password.additionalData.input.key.placeholder')"
                                       :aria-label="$t('password.modal.password.additionalData.input.key.placeholder')"
                                       aria-describedby="basic-addon1"
                                       v-model="state.modal.value.password.additionalData.add.data.key">
                              </div>
                              <div class="col">
                                <div class="input-group mb-1">
                                  <textarea class="k-form-control-less-bold"
                                            :placeholder="$t('password.modal.password.additionalData.input.value.placeholder')"
                                            :aria-label="$t('password.modal.password.additionalData.input.value.placeholder')"
                                            v-model="state.modal.value.password.additionalData.add.data.value"></textarea>
                                </div>
                              </div>
                              <div class="col mt-3 d-flex flex-row-reverse">
                                <div class="">
                                  <button class="btn btn-primary" @click.prevent="addAdditionalData"
                                          :disabled="
                                       state.modal.value.password.additionalData.add.state === 2
                                       ||state.modal.value.password.additionalData.remove.state === 2
                                       ||state.modal.value.password.attachment.remove.state === 2
"
                                  >
                                    {{ $t('password.modal.password.additionalData.input.add') }}
                                  </button>
                                </div>
                                <div class="d-flex me-1 align-items-center">
                                  <div class="spinner-border" role="status"
                                       v-if="
                                       state.modal.value.password.additionalData.add.state === 2
                                       ||state.modal.value.password.additionalData.remove.state === 2
                                       ||state.modal.value.password.attachment.remove.state === 2
">
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="v-pills-attachment" role="tabpanel"
                           aria-labelledby="v-pills-attachment-tab">
                        <div class="container">
                          <div class="row border rounded" style="min-height: 40vh; max-height: 40vh; height: 40vh">
                            <div class="mt-1 h-100">
                              <ul class="list-group list-group-flush h-100 overflow-scroll">
                                <Loading
                                    v-if="[1,2].includes(state.modal.value.password.attachment.retrieve.state)"></Loading>
                                <div
                                    class="d-flex flex-column justify-content-center flex-grow-1 align-items-center text-center"
                                    v-else-if="[3].includes(state.modal.value.password.attachment.retrieve.state)">
                                  <img src="./../../assets/img/file-import-solid.svg"
                                       alt="note"
                                       class="w-25 h-25"
                                  >
                                  <p class="mt-3 w-75 font-weight-400-12px">
                                    {{ $t('password.modal.password.attachment.nothingHere') }}
                                  </p>
                                </div>
                                <li class="list-group-item m-0 p-0 d-flex flex-row justify-content-between"
                                    v-for="d in state.modal.value.password.attachment.retrieve.data"
                                    v-else-if="[4].includes(state.modal.value.password.attachment.retrieve.state)"
                                >
                                  <img :src="getIconUrl(d.file.id, d.jwt)" height="30" width="30">
                                  <a class="clickable list-group-item border-0" target="_blank"
                                     :href="getAttachmentLink(d.file.id, d.jwt)">
                                    {{ d.file.name }}
                                  </a>
                                  <div class="clickable d-flex justify-content-center"
                                       @click.prevent="deleteSingleAttachment(d)">
                                    <img src="./../../assets/img/common/x-lg-black.svg"
                                         style="width: 15px"
                                         alt="close dialog"
                                    >
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col p-0">
                              <!--                              <div class="input-group mb-1">-->
                              <!--                                <input type="text" class="k-form-control-less-bold"-->
                              <!--                                       :placeholder="$t('password.modal.password.attachment.input.key.placeholder')"-->
                              <!--                                       :aria-label="$t('password.modal.password.attachment.input.key.placeholder')"-->
                              <!--                                       aria-describedby="basic-addon1"-->
                              <!--                                       v-model="state.modal.value.password.attachment.add.data.key">-->
                              <!--                              </div>-->
                              <div class="col">
                                <div class="input-group mb-1">
                                  <div class="mb-3 d-flex flex-grow-1">
                                    <input
                                        class="k-form-control"
                                        type="file"
                                        ref="attachmentFile"
                                        @change.prevent="onChangeAttachment($event)"
                                    >
                                  </div>
                                </div>
                              </div>
                              <!--                              <div class="col">-->
                              <!--                                <div class="input-group d-flex justify-content-end align-items-end">-->
                              <!--                                  <button class="btn btn-primary" @click.prevent="addAttachment">-->
                              <!--                                    {{ $t('password.modal.password.attachment.input.add') }}-->
                              <!--                                  </button>-->
                              <!--                                </div>-->
                              <!--                              </div>-->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="v-pills-activity" role="tabpanel"
                           aria-labelledby="v-pills-activity-tab">
                        <div class="container">
                          <div class="row overflow-scroll justify-content-center table-responsive-sm"
                               style="min-height: 40vh; max-height: 40vh">
                            <Loading v-if="state.modal.value.password.activities.loading"></Loading>
                            <table class="table table-sm"
                                   v-else-if="!state.modal.value.password.activities.loading && state.modal.value.password.activities.data.length > 0">
                              <thead>
                              <tr>
                                <th scope="col" class="font-weight-500">
                                  {{ $t('password.modal.password.activities.table.header.date') }}
                                </th>
                                <th scope="col" class="font-weight-500">
                                  {{ $t('password.modal.password.activities.table.header.age') }}
                                </th>
                                <th scope="col" class="font-weight-500">
                                  {{ $t('password.modal.password.activities.table.header.action') }}
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="item in state.modal.value.password.activities.data">
                                <td class="font-weight-400">{{ formatDate(item.create_ts.date) }}</td>
                                <td class="font-weight-400">{{ formatFromNow(item.create_ts.date) }}</td>
                                <td class="font-weight-400">{{ item.data[0] }}</td>
                              </tr>
                              </tbody>
                            </table>
                            <div class="d-flex justify-content-center flex-column align-items-center" v-else>
                              <img src="./../../assets/img/ui-radios.svg"
                                   alt="note"
                                   style="width: 15%"
                              >
                              {{ $t('password.modal.password.activities.noActivityFound') }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tab-pane fade " id="v-pills-share" role="tabpanel"
                           aria-labelledby="v-pills-share-tab">
                        <div class="container">
                          <div class="row overflow-scroll justify-content-center table-responsive-sm"
                               style="min-height: 40vh; max-height: 40vh">
                            <Loading v-if="state.modal.value.password.share.regularShare.loading"></Loading>
                            <table class="table table-sm"
                                   v-else-if="!state.modal.value.password.share.regularShare.loading && state.modal.value.password.share.regularShare.data.length > 0">
                              <thead>
                              <tr>
                                <th scope="col" class="font-weight-500">
                                  {{ $t('password.modal.password.share.table.header.date') }}
                                </th>
                                <th scope="col" class="font-weight-500">
                                  {{ $t('password.modal.password.share.table.header.age') }}
                                </th>
                                <th scope="col" class="font-weight-500">
                                  {{ $t('password.modal.password.share.table.header.action') }}
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr v-for="item in []">
                                <td class="font-weight-400">{{ formatDate(item.create_ts.date) }}</td>
                                <td class="font-weight-400">{{ formatFromNow(item.create_ts.date) }}</td>
                                <td class="font-weight-400">{{ item.data[0] }}</td>
                              </tr>
                              </tbody>
                            </table>
                            <!--                            regular share goes here - currently out of scope, therefore commented out-->
                            <!--                            <div class="d-flex justify-content-center flex-column align-items-center" v-else>-->
                            <!--                              <img src="./../../assets/img/share-nodes.svg" -->
                            <!--                                   alt="note"-->
                            <!--                                   style="width: 15%"-->
                            <!--                              >-->
                            <!--                              {{ $t('password.modal.password.share.regularShare.noShareFound') }}-->
                            <!--                            </div>-->
                            <!-- start styling here -->
                            <div class="container">
                              <div class="row" v-if="state.modal.value.password.share.success.length > 0">
                                <div class="alert alert-success" role="alert"
                                     v-for="success in state.modal.value.password.share.success">
                                  {{ success }}
                                </div>
                              </div>
                              <div class="row mb-5">
                                <div class="col">
                                  <div class="h5">
                                    {{ $t('password.modal.password.share.publicShare.title') }}
                                  </div>
                                  <div class="text-secondary">
                                    {{ $t('password.modal.password.share.publicShare.subtitle') }}
                                  </div>
                                </div>
                              </div>
                              <div id="public-share-body ms-5 me-5 container">
                                <BaseModal
                                    v-if="state.modal.value.password.share.publicShare.modal.isVisible"
                                    :isVisible="state.modal.value.password.share.publicShare.modal.isVisible"
                                    title="Public Share"
                                    primaryButtonText="Verstanden"
                                    secondaryButtonText="Abbrechen"
                                    @close="onGenerateShareLinkUnacknowledged"
                                    @primary-click="onGenerateShareLinkAcknowledged"
                                    @secondary-click="onGenerateShareLinkUnacknowledged"
                                >
                                  <small class="text-secondary font-weight-400 m-0 p-0">
                                    {{ $t('password.modal.password.share.publicShare.publicShareDecryptInfo') }}
                                  </small>
                                </BaseModal>
                                <div class="row d-flex justify-content-baseline align-items-center">
                                  <div class="col-5">
                                    <label class="form-label ms-1">{{
                                        $t('password.modal.password.share.publicShare.expireDate')
                                      }}*</label>
                                    <Datepicker
                                        v-model="state.modal.value.password.share.publicShare.expireDate"
                                        class="form-control d-flex flex-grow-1 k-form-control-less-bold"
                                    ></Datepicker>
                                  </div>

                                  <div class="col-7">

                                    <div class="row d-flex justify-content-start">
                                      <input class="form-check-input me-1" type="checkbox"
                                             :checked="state.modal.value.password.update.selected?.node?.public_share"
                                             @change.prevent="onGenerateShareClick">

                                      {{ getPublicShareInfo() }}

                                    </div>

                                  </div>

                                </div>
                                <div class="row mt-3">
                                  <div class="col-5">
                                    <label class="form-label ms-1">{{
                                        $t('password.modal.password.share.publicShare.password')
                                      }}*</label>
                                    <div class="input-group">
                                      <input @blur="onPublicSharePasswordSet"
                                             :readonly="state.modal.value.password.update.selected?.node?.public_share !== null"
                                             v-model="state.__I_AM_THE_PASSWORD_FOR_PUBLIC_SHARE_BUT_BECAUSE_OF_GROWING_APP_ARCHITECTURE_I_CAN_NOT_BE_ON_MY_RIGHT_PLACE_AND_NEED_TO_GET_REFACTORED_SOON__.value"
                                             type="text"
                                             class="form-control k-form-control-less-bold"
                                             :placeholder="$t('password.modal.password.share.publicShare.password')"
                                             :aria-label="$t('password.modal.password.share.publicShare.password')"
                                             required
                                      >
                                    </div>
                                  </div>
                                  <div class="col-7">
                                    <div class="row">
                                      <button class="btn btn-light button-border input-group-text clickable" id="copy"
                                              @click.prevent="copyLink"
                                              v-if="state.modal.value.password.update.selected?.node?.public_share">
                                        <img src="./../../assets/img/common/files.svg"
                                             alt="add password"
                                        >
                                      </button>
                                      <small class="text-secondary font-weight-300 m-0 p-0"
                                             v-else>
                                        {{ $t('password.modal.password.share.publicShare.publicShareDecryptInfo') }}
                                      </small>

                                    </div>
                                  </div>
                                </div>
                                <Loading v-if="state.modal.value.password.share.publicShare.loading"></Loading>
                                <div class="text-secondary font-weight-500"
                                     v-if="false && state.modal.value.password.update.selected?.node?.public_share">
                                  <small>
                                    {{ $t('password.modal.password.share.publicShare.viewedBy', {times: 10}) }}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div class="modal-footer" v-if="state.modal.value.password.update.showButtons">
              <div class="spinner-border" role="status" v-if="state.modal.value.password.update.interacting"></div>
<!--              <button type="button" class="btn btn-primary" @click="updateCredential">-->
<!--                {{ $t("password.modal.password.update.form.button") }}-->
<!--              </button>-->
              <button type="button" class="btn btn-secondary text-white"
                      @click.prevent="state.modal.value.password.update.instance.hide()">
                {{ $t("password.modal.password.update.form.closeButton") }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" tabindex="-1" aria-labelledby=""
           aria-hidden="true" ref="addFolder">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content k-modal-content">
            <div class="modal-header d-flex justify-content-between">
              <h5 class="modal-title">
                <img src="./../../assets/img/password/folder-fill-white.svg"
                     alt="add password"
                >

                {{ $t("password.modal.folder.title") }}
              </h5>
              <div @click="state.modal.value.folder.instance.hide()" class="clickable">
                <img src="./../../assets/img/common/x-lg-white.svg"
                     alt="close dialog"
                >
              </div>
            </div>
            <div class="modal-body">
              <form>
                <div class="row">
                  <div class="col mb-4">
                    <label for="folder-name" class="modal-label mb-1">
                      <small>{{ $t("password.modal.folder.form.name.title") }}</small>
                    </label>
                    <input type="text" id="folder-name" class="k-form-control-less-bold-500 modal-input"
                           :placeholder="$t('password.modal.folder.form.name.placeholder')"
                           v-model="state.modal.value.folder.form.name">
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="addMoreInputIdSecond"
                       v-model="state.modal.value.folder.form.addMore">
                <label class="form-check-label" for="addMoreInputIdSecond">
                  Add More
                </label>
              </div>
              <button type="button" class="k-btn btn-primary" @click="createFolder"
              >
                {{ $t("password.modal.folder.form.addButton") }}
              </button>
              <button type="button" class="k-btn btn-secondary text-white"
                      @click="state.modal.value.folder.instance.hide()">
                {{ $t("password.modal.folder.form.closeButton") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <ContextMenu ref="menu">
        <template v-slot:edge="{edge}">
<!--          <ContextMenuItem @onClick="() => onRegularShare(edge, this.$refs.menu)">-->
<!--            {{ $t('password.modal.threedots.share') }}-->
<!--          </ContextMenuItem>-->
          <ContextMenuItem @onClick="() => onDelete(edge, this.$refs.menu)">
            {{ $t('password.modal.threedots.delete') }}
          </ContextMenuItem>
          <ContextMenuItem v-if="(((edge || {}).node || {}).type || {}) === 'folder'"
                           @onClick="()=>onRename(edge,this.$refs.menu)">
            {{ $t('password.modal.threedots.rename') }}
          </ContextMenuItem>
        </template>
      </ContextMenu>
    </template>
  </View>
</template>

<script>
import View from "@/components/Common/View";
import ContextMenu from "@/components/Common/ContextMenu/ContextMenu";
import ContextMenuItem from "@/components/Common/ContextMenu/ContextMenuItem";
import Datepicker from 'vue3-datepicker'
import {
  AES_SERVICE,
  API_SERVICE,
  APP_STORAGE,
  BASE64_SERVICE,
  ContainerFactory,
  CRYPTO_JS_HELPER_SERVICE,
  KEESTASH_ENCRYPTION_SERVICE
} from "@/src/Container/ContainerFactory";
import {ROUTES} from "@/src/Config/Password/routes";
import {CONFIG} from "@/src/Config/Password/config";
import {computed, onBeforeUnmount, onMounted, reactive, ref, toRefs, watch} from "vue";
import moment from "moment";
import EmptyPasswords from "@/components/Password/EmptyPasswords";
import LoadingPasswords from "@/components/Password/LoadingPasswords";
import {Modal} from "bootstrap";
import {useStore} from "vuex";
import PasswordField from "@/components/Common/PasswordField";
import {useI18n} from "vue-i18n";
import Loading from "@/components/Common/Loading.vue";
import Entropy from "@/components/Password/Entropy.vue";
import {
  STATE_INITIALIZED,
  STATE_INITIALIZING,
  STATE_INTERACTED_EMPTY,
  STATE_INTERACTED_NON_EMPTY,
  STATE_INTERACTING
} from "@/src/Service/UI/State";
import {useRouter} from "vue-router";
import BaseModal from "@/components/Common/BaseModal.vue";

export default {
  name: "Password",
  components: {
    BaseModal,
    Entropy,
    Loading,
    PasswordField,
    EmptyPasswords,
    View,
    ContextMenu,
    ContextMenuItem,
    LoadingPasswords,
    Datepicker
  },
  setup() {
    const store = useStore();
    const i18n = useI18n();
    const state = reactive(
        {
          __I_AM_THE_PASSWORD_FOR_PUBLIC_SHARE_BUT_BECAUSE_OF_GROWING_APP_ARCHITECTURE_I_CAN_NOT_BE_ON_MY_RIGHT_PLACE_AND_NEED_TO_GET_REFACTORED_SOON__: '',
          node: {
            id: null,
            edges: {
              content: []
            }
          },
          breadCrumb: [],
          pwned: {
            passwords: [],
            breaches: {}
          },
          modal: {
            password: {
              add: {
                instance: null,
                selected: null,
                form: {
                  name: null,
                  userName: null,
                  password: {
                    value: '',
                    focused: false,
                    entropy: {
                      value: 0,
                      loading: false
                    }
                  },
                  url: null,
                  addMore: false,
                  interacting: false
                },
                passwordGenerator: {
                  passwordGeneratorShown: false,
                  values: {
                    upperCase: false,
                    lowerCase: false,
                    digits: false,
                    specialChar: false,
                    length: 8
                  },
                  generated: {
                    password: {
                      value: null,
                      character_set: null,
                      entropy: null,
                      quality: null
                    }
                  }
                },
                errors: [],
                success: [],
              },
              update: {
                showButtons: true,
                errors: [],
                success: [],
                selected: null,
                instance: null,
                passwordGenerator: {
                  passwordGeneratorShown: false,
                  values: {
                    upperCase: false,
                    lowerCase: false,
                    digits: false,
                    specialChar: false,
                    length: 8
                  },
                  generated: {
                    password: {
                      value: null,
                      character_set: null,
                      entropy: null,
                      quality: null
                    }
                  }
                },
                form: {
                  password: '',
                  passwordField: null,
                  name: null,
                  userName: null,
                  url: null,
                  entropy: {
                    value: 0,
                    loading: false
                  }
                },
                interacting: false,
                redirect: {
                  instance: null,
                  url: null
                }
              },
              additionalData: {
                retrieve: {
                  state: STATE_INITIALIZING,
                  data: [],
                  retrieved: {
                    value: null,
                    loading: false
                  }
                },
                add: {
                  state: STATE_INITIALIZED,
                  data: {
                    key: null,
                    value: null
                  }
                },
                remove: {
                  state: STATE_INITIALIZED
                },
              },
              attachment: {
                retrieve: {
                  state: STATE_INITIALIZED,
                  data: [],
                  retrieved: {
                    value: null,
                    loading: false
                  }
                },
                add: {
                  data: {
                    key: null,
                    value: null
                  }
                },
                remove: {
                  state: STATE_INITIALIZED
                }
              },
              activities: {
                loading: false,
                data: []
              },
              share: {
                success: [],
                errors: [],
                regularShare: {
                  loading: false,
                  data: []
                },
                publicShare: {
                  loading: false,
                  expireDate: null,
                  modal: {
                    isVisible: false
                  }
                }

              }
            },
            folder: {
              instance: null,
              form: {
                name: null,
                addMore: false
              },
              rename: {
                instance: null,
                name: null,
                node_id: null
              }
            },
            node: {
              delete: {
                instance: null,
                node_id: null
              },
              share: {
                regular: {
                  node_id: null,
                  instance: null,
                }
              }
            }
          },
          state: CONFIG.VIEW_STATE.CREATED,
          loadEdgesAbortController: null,
          drag: {
            selected: null
          },
          passwordTimeOutHandle: null,
        }
    );

    const addModal = ref(null);
    const renameModal = ref(null);
    const redirectModal = ref(null);
    const deleteModal = ref(null);
    const regularShareModal = ref(null);
    const addFolder = ref(null);
    const updateModal = ref(null);
    const containerFactory = new ContainerFactory();
    const container = containerFactory.create();
    /** @var {ApiService} apiService */
    const apiService = container.get(API_SERVICE);
    /** @var {AppStorage} apiService */
    const appStorage = container.get(APP_STORAGE);
    /** @var {AesService} aesService */
    const aesService = container.get(AES_SERVICE);
    /** @var {Base64Service} base64Service */
    const base64Service = container.get(BASE64_SERVICE);
    /** @var {CryptoJsHelper} cryptoJsHelper */
    const cryptoJsHelper = container.get(CRYPTO_JS_HELPER_SERVICE);
    /** @var {KeestashEncryptionService} keestashEncryptionService */
    const keestashEncryptionService = container.get(KEESTASH_ENCRYPTION_SERVICE);

    const search = computed(
        function () {
          return store.state.search;
        }
    )

    const keyFromStore = computed(() => store.state.key);
    const derivationFromStore = computed(() => store.state.derivation);

    const showError = useRouter().currentRoute.value.query.showError || false;

    // password
    function onOpenLink() {
      this.state.modal.value.password.update.redirect.instance = new Modal(redirectModal.value);
      this.state.modal.value.password.update.redirect.url = this.state.modal.value.password.update.form.url;
      this.state.modal.value.password.update.instance.hide();
      this.state.modal.value.password.update.redirect.instance.show();
    }

    function redirect() {
      let url = this.state.modal.value.password.update.redirect.url;
      if (!url.match(/^[a-zA-Z]+:\/\//)) {
        url = 'http://' + url;
      }
      window.location.href = url;
    }

    function generatePassword(caller) {
      state.modal.password.update.interacting = true;
      const length = caller === 'update'
          ? state.modal.password.update.passwordGenerator.values.length
          : state.modal.password.add.passwordGenerator.values.length;
      const upperCase = caller === 'update'
          ? state.modal.password.update.passwordGenerator.values.upperCase
          : state.modal.password.add.passwordGenerator.values.upperCase;
      const lowerCase = caller === 'update'
          ? state.modal.password.update.passwordGenerator.values.lowerCase
          : state.modal.password.add.passwordGenerator.values.lowerCase;
      const digits = caller === 'update'
          ? state.modal.password.update.passwordGenerator.values.digits
          : state.modal.password.add.passwordGenerator.values.digits;
      const specialChar = caller === 'update'
          ? state.modal.password.update.passwordGenerator.values.specialChar
          : state.modal.password.add.passwordGenerator.values.specialChar;
      apiService.get(
          ROUTES.getGeneratePassword(
              length,
              upperCase,
              lowerCase,
              digits,
              specialChar
          )
      )
          .then(
              (response) => {
                if (caller === 'update') {
                  state.modal.password.update.passwordGenerator.generated.password = response.data.password;
                  state.modal.password.update.interacting = false;
                }
                if (caller === 'add') {
                  state.modal.password.add.passwordGenerator.generated.password = response.data.password;
                  state.modal.password.update.interacting = false;
                }
              }
          )
    }

    function copyPassword(caller) {
      state.modal.password.update.interacting = true;
      const generated = caller === 'update'
          ? state.modal.password.update.passwordGenerator.generated.password.value
          : state.modal.password.add.passwordGenerator.generated.password.value;

      navigator.clipboard.writeText(generated)
          .then(
              function () {
                console.log('clipboard successfully set');
                state.modal.password.update.interacting = false;
                state.modal.password.update.success.push('copied to clipboard');
                clearAlerts('update');
              }
          )
          .catch(
              (e) => {
                console.log(e)
                console.log('clipboard write failed');
                state.modal.password.update.interacting = false;
                state.modal.password.update.errors.push(e);
                clearAlerts('update');
              }
          )

    }

    function onEyeClick(c) {
      state.modal.password.update.interacting = true;
      state.modal.password.update.form.passwordField = c;
      if (c.visible) {
        c.visible = !c.visible;
        state.modal.password.update.form.password = null;
        state.modal.password.update.interacting = false;
        state.modal.password.update.form.entropy.value = 0;
        if (state.passwordTimeOutHandle !== null) {
          clearTimeout(state.passwordTimeOutHandle)
        }
        return;
      }

      apiService.get(
          ROUTES.getCredential(
              state.modal.password.update.selected.node.id
          )
      )
          .then(
              function (data) {
                state.modal.password.update.form.password = keestashEncryptionService.decrypt(data.data.password);
                c.visible = !c.visible;
                state.modal.password.update.interacting = false;
              }
          )
          .then(
              function () {
                setPasswordTimeout(c);
                state.modal.password.update.form.entropy.value = 0;
              }
          )
    }

    function setPasswordTimeout(c) {
      if (state.passwordTimeOutHandle !== null) {
        clearTimeout(state.passwordTimeOutHandle)
      }
      state.passwordTimeOutHandle = setTimeout(
          function () {
            state.modal.password.update.form.password = null;
            c.visible = !c.visible;
            state.modal.password.update.interacting = false;
            state.modal.password.update.form.entropy.value = 0;
          },
          10000
      );
    }

    function onFocusNewPassword() {
      state.modal.password.add.form.password.focused = !state.modal.password.add.form.password.focused;
    }

    function onPasswordGeneratorShown() {
      state.modal.password.update.passwordGenerator.passwordGeneratorShown = !state.modal.password.update.passwordGenerator.passwordGeneratorShown;
    }

    function onPasswordGeneratorAddShown() {
      state.modal.password.add.passwordGenerator.passwordGeneratorShown = !state.modal.password.add.passwordGenerator.passwordGeneratorShown;
    }

    function clearErrors(type) {
      setTimeout(
          function () {
            if (type === 'update') {
              state.modal.password.update.errors = [];
            } else if (type === 'add') {
              state.modal.password.add.errors = [];
            } else if (type === 'share') {
              state.modal.password.share.errors = [];
            }

          },
          3000
      )
    }

    function clearSuccess(type) {
      setTimeout(
          function () {
            if (type === 'update') {
              state.modal.password.update.success = [];
            } else if (type === 'add') {
              state.modal.password.add.success = [];
            } else if (type === 'share') {
              state.modal.password.share.success = [];
            }
          },
          3000
      )
    }

    function clearAlerts(type) {
      clearErrors(type);
      clearSuccess(type);
    }

    function onCopyClick() {
      state.modal.password.update.interacting = true;
      apiService.get(
          ROUTES.getCredential(
              state.modal.password.update.selected.node.id
          )
      )
          .then(
              function (response) {
                state.modal.password.update.interacting = false;
                setTimeout(
                    async () => {
                      navigator.clipboard.writeText(response.data.decrypted)
                          .then(
                              function () {
                                console.log('clipboard successfully set')
                                state.modal.password.update.success.push('copied');
                                clearAlerts('update');
                              }
                          )
                          .catch(
                              function (e) {
                                state.modal.password.update.interacting = false;
                                const errorMessage = showError ? e.toString() : 'copy failed :(';
                                state.modal.password.update.errors.push(errorMessage);
                                clearAlerts('update');
                              }
                          )
                    }
                )

              }
          )
    }

    function loadEdges(id) {
      const loadEdgesController = new AbortController();
      state.loadEdgesAbortController = loadEdgesController;

      updateState(CONFIG.VIEW_STATE.LOADING);

      apiService.get(
          ROUTES.getNode(id || CONFIG.NODE_ID_ROOT),
          {},
          loadEdgesController,
          {
            "x-keestash-api-version": 2
          }
      )
          .then(
              function (response) {
                state.node = response.data.node;
                state.breadCrumb = response.data.breadCrumb;
                state.pwned = response.data.pwned;
                updateState(CONFIG.VIEW_STATE.LOADED);
                appStorage.set('selected', state.node.id);
              }
          )
          .catch(
              function (r) {
                updateState(CONFIG.VIEW_STATE.LOADED);
              }
          )
    }

    function updateState(newState) {
      state.state = newState;
    }

    function showEmptyScreen() {
      return state.state === CONFIG.VIEW_STATE.LOADED
          && state.node.edges.content.length === 0;
    }

    function showLoadingScreen() {
      return state.state === CONFIG.VIEW_STATE.LOADING;
    }

    function useGeneratedPassword(caller) {
      state.modal.password.update.interacting = true;
      const passwordPlain = caller === 'update'
          ? state.modal.password.update.passwordGenerator.generated.password.value
          : state.modal.password.add.passwordGenerator.generated.password.value;

      if (caller === 'add') {
        state.modal.password.add.form.password.value = passwordPlain;
        return;
      }

      apiService.post(
          ROUTES.updatePassword(),
          {
            password: keestashEncryptionService.encrypt(passwordPlain),
            nodeId: state.modal.password.update.selected.node.id
          }
      )
          .then(
              function (res) {
                // TODO update node in state.node.edges.content
                state.modal.password.update.interacting = false;
                state.modal.password.update.passwordGenerator.generated.password.value = '';
                state.modal.password.update.success.push('password updated');
                clearAlerts('update');
              }
          )
          .catch(
              function () {
                state.modal.password.update.interacting = false;
                state.modal.password.update.errors.push('error updating password');
                clearAlerts('update');
              }
          )
    }

    function createPassword() {

      if (true === state.modal.password.add.form.interacting) {
        return;
      }
      state.modal.password.add.form.interacting = true;

      apiService.post(
          ROUTES.createPassword(),
          {
            name: state.modal.password.add.form.name,
            username: keestashEncryptionService.encrypt(state.modal.password.add.form.userName),
            password: keestashEncryptionService.encrypt(state.modal.password.add.form.password.value),
            parent: state.node.id,
            url: keestashEncryptionService.encrypt(state.modal.password.add.form.url)
          }
      )
          .then(
              function (response) {
                state.modal.password.add.form.interacting = false;
                state.node.edges.content.push(
                    response.data.edge
                );
                clearAddModal();

                if (false === state.modal.password.add.form.addMore) {
                  destroyAddModal();
                  return;
                }
                state.modal.password.add.success.push('added');
                clearAlerts('add');
              }
          )
          .catch(
              function (response) {
                state.modal.password.add.form.interacting = false;
                state.modal.password.add.errors.push('error adding');
                clearAlerts('add');
              }
          )
    }

    function updateCredential() {
      let password = null;
      if (state.modal.password.update.form.password !== null) {
        password = state.modal.password.update.form.password;
      }
      state.modal.password.update.interacting = true;
      apiService.post(
          ROUTES.updateCredential(),
          {
            name: state.modal.password.update.form.name,
            username: keestashEncryptionService.encrypt(state.modal.password.update.form.userName),
            url: keestashEncryptionService.encrypt(state.modal.password.update.form.url),
            nodeId: state.modal.password.update.selected.node.id,
            password: keestashEncryptionService.encrypt(password)
          },
          {
            "x-keestash-api-version": 2
          }
      )
          .then(
              function () {
                state.modal.password.update.success.push('updated');
                clearAlerts('update');
                state.modal.password.update.interacting = false;
              }
          )
          .catch(
              function (response) {
                state.modal.password.update.success.push('error updating');
                clearAlerts('update');
                state.modal.password.update.interacting = false;
              }
          )
    }

    function createFolder() {
      apiService.post(
          ROUTES.createFolder(),
          {
            name: state.modal.folder.form.name,
            node_id: state.node.id || CONFIG.NODE_ID_ROOT
          }
      )
          .then(
              function (response) {
                state.node.edges.content.push(
                    response.data.edge
                );
                clearAddFolder();
                if (false === state.modal.folder.form.addMore) {
                  destroyAddFolder();
                }

              }
          )
          .catch(
              function (response) {
                console.log(response)
              }
          )
    }

    function removeFromState(id) {
      state.node.edges.content = state.node.edges.content.filter(
          function (edge) {
            return edge.node.id !== id;
          }
      );
    }

    function updateEdgeName(nodeId, name) {
      for (let i = 0; i < state.node.edge_size; i++) {
        if (state.node.edges.content[i].node.id === nodeId) {
          state.node.edges.content[i].node.name = name;
        }
      }
    }

    function onDelete(edge, menu) {
      state.modal.node.delete.node_id = edge.node.id;
      state.modal.node.delete.instance = new Modal(deleteModal.value);
      state.modal.node.delete.instance.show();
      deleteModal.value.addEventListener(
          'hidden.bs.modal',
          function () {
            state.modal.node.delete.instance = null;
            state.modal.node.delete.node_id = null;
            menu.close();
          },
          false
      );
    }
    function onRegularShare(edge, menu) {
      state.modal.node.share.regular.node_id = edge.node.id;
      state.modal.node.share.regular.instance = new Modal(regularShareModal.value);
      state.modal.node.share.regular.instance.show();
      regularShareModal.value.addEventListener(
          'hidden.bs.modal',
          function () {
            state.modal.node.share.regular.instance = null;
            state.modal.node.share.regular.node_id = null;
            menu.close();
          },
          false
      );
    }


    function deleteNode() {
      apiService.delete(
          ROUTES.removeNode(),
          {
            node_id: state.modal.node.delete.node_id
          }
      )
          .then(
              function () {
                removeFromState(state.modal.node.delete.node_id);
                state.modal.node.delete.instance.hide();
              }
          )
          .catch(
              function (response) {
                console.log(response)
              }
          )
    }

    function renameNode() {
      apiService.post(
          ROUTES.updateNode(),
          {
            node_id: state.modal.folder.rename.node_id,
            name: state.modal.folder.rename.name
          }
      )
          .then(
              function () {
                updateEdgeName(
                    state.modal.folder.rename.node_id
                    , state.modal.folder.rename.name
                );
                state.modal.node.delete.instance.hide();
              }
          )
          .catch(
              function (response) {
                console.log(response)
              }
          )
    }

    function onRename(edge, menu) {
      state.modal.folder.rename.node_id = edge.node.id;
      state.modal.folder.rename.instance = new Modal(renameModal.value);
      state.modal.folder.rename.instance.show();
      renameModal.value.addEventListener(
          'hidden.bs.modal',
          function () {
            state.modal.folder.rename.name = null;
            state.modal.folder.rename.node_id = null;
            menu.close();
          },
          false
      );
    }

    function onThreeDotsClick(event, edge, menu) {
      event.preventDefault();
      event.stopPropagation();
      menu.open(event, edge);
    }

    function clearPasswordGeneratorUpdate() {
      state.modal.password.update.passwordGenerator.passwordGeneratorShown = false;
      state.modal.password.update.passwordGenerator.values.upperCase = false;
      state.modal.password.update.passwordGenerator.values.lowerCase = false;
      state.modal.password.update.passwordGenerator.values.digits = false;
      state.modal.password.update.passwordGenerator.values.specialChar = false;
      state.modal.password.update.passwordGenerator.values.length = 8;
    }

    function clearPasswordGeneratorAdd() {
      state.modal.password.add.passwordGenerator.passwordGeneratorShown = false;
      state.modal.password.add.passwordGenerator.values.upperCase = false;
      state.modal.password.add.passwordGenerator.values.lowerCase = false;
      state.modal.password.add.passwordGenerator.values.digits = false;
      state.modal.password.add.passwordGenerator.values.specialChar = false;
      state.modal.password.add.passwordGenerator.values.length = 8;
    }

    function clearUpdateModal() {
      state.modal.password.update.form.password = null;
      state.modal.password.update.form.name = null;
      state.modal.password.update.form.userName = null;
      state.modal.password.update.form.url = null;
      state.modal.password.update.instance = null;
      state.modal.password.update.selected = null;
      state.modal.password.update.interacting = false;

      clearPasswordGeneratorUpdate();

      state.modal.password.update.passwordGenerator.generated.password.value = null;
      state.modal.password.update.passwordGenerator.generated.password.character_set = null;
      state.modal.password.update.passwordGenerator.generated.password.entropy = null;
      state.modal.password.update.passwordGenerator.generated.password.quality = null;

      if (state.modal.password.update.form.passwordField !== null) {
        state.modal.password.update.form.passwordField.visible = false;
        state.modal.password.update.form.password = null;
      }

      state.modal.password.additionalData.retrieve.data = [];

      state.modal.password.additionalData.retrieve.state = STATE_INITIALIZED;
      state.modal.password.additionalData.retrieve.retrieved.value = null;
      state.modal.password.additionalData.retrieve.retrieved.loading = false;
      state.modal.password.additionalData.add.state = STATE_INITIALIZED;

      state.modal.password.additionalData.add.data.key = null;
      state.modal.password.additionalData.add.data.value = null;

      state.modal.password.attachment.retrieve.state = STATE_INITIALIZED;
      state.modal.password.attachment.retrieve.data = [];
      state.modal.password.attachment.retrieve.retrieved.value = null;
      state.modal.password.attachment.retrieve.retrieved.loading = false;

      state.modal.password.attachment.add.data.key = null;
      state.modal.password.attachment.add.data.value = null;

      state.modal.password.activities.loading = false;
      state.modal.password.activities.data = [];
      clearTimeout(state.passwordTimeOutHandle);
      document.getElementById('v-pills-home-tab').click();

    }

    function destroyAddModal() {
      state.modal.password.add.instance.hide();
      state.modal.password.add.instance = null;
    }

    function clearAddModal() {
      state.modal.password.add.selected = null;
      state.modal.password.add.form.name = null;
      state.modal.password.add.form.userName = null;
      state.modal.password.add.form.password.value = null;
      state.modal.password.add.form.password.focused = false;
      state.modal.password.add.form.url = null;
      state.modal.password.add.form.password.entropy.value = 0;
      state.modal.password.add.form.password.entropy.loading = false;

      clearPasswordGeneratorAdd();

      state.modal.password.add.passwordGenerator.generated.password.value = null;
      state.modal.password.add.passwordGenerator.generated.password.character_set = null;
      state.modal.password.add.passwordGenerator.generated.password.entropy = null;
      state.modal.password.add.passwordGenerator.generated.password.quality = null;

    }

    function destroyAddFolder() {
      state.modal.folder.instance.hide();
      state.modal.folder.instance = null;
    }

    function clearAddFolder() {
      state.modal.folder.form.name = null;
    }

    function onAddPassword() {
      const self = this;
      state.modal.password.add.instance = new Modal(addModal.value);
      state.modal.password.add.instance.show();

      addModal.value.addEventListener(
          'hidden.bs.modal',
          function () {
            self.clearAddModal();
            state.modal.password.add.form.addMore = false;
            state.modal.password.add.form.interacting = false;
          }
          , false
      );
    }

    function onAddFolder() {
      const self = this;
      state.modal.folder.instance = new Modal(addFolder.value);
      state.modal.folder.instance.show();
      addFolder.value.addEventListener(
          'hidden.bs.modal',
          function () {
            self.clearAddFolder();
            state.modal.folder.form.addMore = false;
          },
          false
      );
    }

    function onEdgeClick(edge) {
      if (edge.node.type === 'folder') {
        loadEdges(edge.node.id);
        return;
      }
      state.modal.password.update.selected = edge;
      state.modal.password.update.form.name = edge.node.name;
      state.modal.password.update.form.userName = keestashEncryptionService.decrypt(edge.node.username);
      state.modal.password.update.form.url = keestashEncryptionService.decrypt(edge.node.url);
      state.modal.password.update.instance = new Modal(updateModal.value);
      state.modal.password.update.instance.show();

      updateModal.value.addEventListener(
          'hidden.bs.modal',
          function () {
            clearUpdateModal();
          },
          false
      );
    }

    function getEntropy(entropy) {

      entropy = Math.floor(entropy)
      let source = 'file-fill-darkgreen.svg';

      if (entropy <= 100) {
        source = 'file-fill-darkred.svg';
      } else if (entropy > 100 && entropy < 250) {
        source = 'file-fill-darkyellow.svg';
      }

      return require('../../assets/img/common/' + source);
    }

    function getHealthType(nodeId) {
      let healthType = 0;
      let inBreaches = nodeId in state.pwned.breaches;
      let inPasswords = nodeId in state.pwned.passwords;

      if (inBreaches && inPasswords) {
        healthType = 1;
      } else if (inBreaches) {
        healthType = 2;
      } else if (inPasswords) {
        healthType = 3;
      }
      return healthType;
    }

    function getHealthDescription(nodeId) {
      switch (getHealthType(nodeId)) {
        case 0:
          return i18n.t('password.table.body.health.description.0');
        case 1:
          return i18n.t('password.table.body.health.description.1');
        case 2:
          return i18n.t('password.table.body.health.description.2');
        case 3:
          return i18n.t('password.table.body.health.description.3');
        default:
          throw new Error();
      }
    }

    function getHealthIcon(nodeId) {
      switch (getHealthType(nodeId)) {
        case 0:
          return require('../../assets/img/common/file-fill-darkgreen.svg');
        case 1:
          return require('../../assets/img/common/file-fill-darkred.svg');
        case 2:
          return require('../../assets/img/common/file-fill-darkred.svg');
        case 3:
          return require('../../assets/img/common/file-fill-darkred.svg');
        default:
          throw new Error();
      }
    }

    function formatDate(date) {
      return moment(date).format('DD.MM.YYYY HH:mm');
    }

    function formatFromNow(date) {
      return moment(date, "YYYYMMDD").fromNow()
    }

    function startDragging(ev, edge) {
      const dti = ev.dataTransfer.items;
      if (dti === undefined || dti == null) {
        console.log("Browser does not support DataTransferItem interface");
        return;
      }
      // Add the id of the drag source element to the drag data payload so
      // it is available when the drop event is fired
      dti.add(ev.target.id, "text/plain");
      ev.dataTransfer.setData('object', edge);
      // Tell the browser both copy and move are possible
      ev.effectAllowed = "copyMove";
      state.drag.selected = edge;
    }

    function endDragging(ev) {
      const dti = ev.dataTransfer.items;
      if (dti === undefined || dti == null) {
        console.log("Browser does not support DataTransferItem interface");
        return;
      }
      // Remove all of the items from the list.
      dti.clear();
      state.drag.selected = null;
    }

    function drop(ev, targetNodeId) {
      ev.preventDefault();
      ev.currentTarget.style.background = "#ffffff";

      const dti = ev.dataTransfer.items;
      if (dti === undefined || dti == null) {
        console.log("Browser does not support DataTransferItem interface");
        return;
      }

      const nodeId = state.drag.selected.node.id;

      if (nodeId === targetNodeId) {
        console.log("cannot move node to itself");
        return;
      }
      apiService.post(
          ROUTES.move()
          , {
            'node_id': nodeId
            , 'target_node_id': targetNodeId
          }
      )
          .then(
              function () {
                removeFromState(nodeId)
              })
          .catch(
              function (r) {
                console.error(r)
              }
          )
    }

    function dragover(ev) {
      var dti = ev.dataTransfer.items;
      if (dti === undefined || dti == null) {
        console.log("Browser does not support DataTransferItem interface");
        return;
      }
      // Change the target element's border to signify a drag over event
      // has occurred
      ev.currentTarget.style.background = "#cfeefc";
      ev.preventDefault();
    }

    function dragleave(ev) {
      var dti = ev.dataTransfer.items;
      if (dti === undefined || dti == null) {
        console.log("Browser does not support DataTransferItem interface");
        return;
      }
      // Change the target element's border to signify a drag over event
      // has occurred
      ev.currentTarget.style.background = "#ffffff";
      ev.preventDefault();
    }

    function onUsernameCopy() {
      state.modal.password.update.interacting = true;
      navigator.clipboard.writeText(state.modal.password.update.form.userName)
          .then(
              function () {
                state.modal.password.update.interacting = false;
                state.modal.password.update.success.push('username copied');
                clearAlerts('update');
              }
          )
          .catch(
              function (e) {
                state.modal.password.update.interacting = false;
                console.log(e)
                console.log('clipboard write failed')
                state.modal.password.update.errors.push('copy failed :(');
                clearAlerts('update');
              }
          )
    }

    function onAdditionalDataClick() {
      if (state.modal.password.additionalData.retrieve.state === STATE_INTERACTING) {
        return;
      }

      state.modal.password.update.showButtons = false;
      state.modal.password.additionalData.retrieve.state = STATE_INTERACTING;
      apiService.get(
          ROUTES.getAdditionalData(
              state.modal.password.update.selected.node.id
          )
      ).then(
          function (r) {
            const data = r.data.data;
            const dataCount = r.data.data.length;

            state.modal.password.additionalData.retrieve.data = data;
            state.modal.password.additionalData.retrieve.state =
                dataCount > 0
                    ? STATE_INTERACTED_NON_EMPTY
                    : STATE_INTERACTED_EMPTY;
          }
      )
    }

    function deleteSingleAttachment(nodeFile) {
      if (state.modal.password.attachment.retrieve.state === STATE_INTERACTING) {
        return;
      }
      state.modal.password.attachment.retrieve.state = STATE_INTERACTING;

      apiService.delete(
          ROUTES.removeAttachment(),
          {
            node: {
              id: nodeFile.node.id,
            },
            file: {
              id: nodeFile.file.id
            }
          }
      )
          .then(
              () => {
                state.modal.password.attachment.retrieve.data =
                    state.modal.password.attachment.retrieve.data.filter(
                        function (data) {
                          return data.file.id !== nodeFile.file.id
                        }
                    );

              }
          )
    }

    function getIconUrl(id, jwt) {
      return ROUTES.getIconUrl(id, jwt);
    }

    function getAttachmentLink(id, jwt) {
      return ROUTES.getDownloadLink(id, jwt);
    }


    function onAttachmentClick() {
      if (state.modal.password.attachment.retrieve.state === STATE_INTERACTING) {
        return;
      }

      state.modal.password.update.showButtons = false;
      state.modal.password.attachment.retrieve.state = STATE_INTERACTING;
      apiService.get(
          ROUTES.getAttachmentsByNodeId(
              state.modal.password.update.selected.node.id
          )
      ).then(
          function (r) {
            const data = r.data.fileList.content;
            const dataCount = data.length;

            const newList = [];
            for (let i = 0; i < dataCount; i++) {
              newList.push(
                  // todo decrypt
                  r.da.fileList.content[i]
              )
            }
            state.modal.password.attachment.retrieve.data = newList;
            state.modal.password.attachment.retrieve.state =
                dataCount > 0
                    ? STATE_INTERACTED_NON_EMPTY
                    : STATE_INTERACTED_EMPTY;

          }
      )
    }

    function onActivityClick() {
      if (state.modal.password.activities.loading) {
        return;
      }
      state.modal.password.update.showButtons = false;
      state.modal.password.activities.loading = true;
      apiService.get(
          ROUTES.getPasswordManagerActivities(
              state.modal.password.update.selected.node.id,
              'passwordManager'
          )
      )
          .then(
              function (r) {
                state.modal.password.activities.data = r.data.activityList;
                state.modal.password.activities.loading = false;
              }
          ).catch(
          function () {
            state.modal.password.activities.data = [];
            state.modal.password.activities.loading = false;
          }
      )
    }

    function onShareClick() {
      if (state.modal.password.share.loading) {
        return;
      }
      state.modal.password.update.showButtons = false;
      state.modal.password.share.loading = false;

      // TODO start coding here
      // apiService.get(
      //     ROUTES.getPasswordManagerActivities(
      //         state.modal.password.update.selected.node.id,
      //         'passwordManager'
      //     )
      // )
      //     .then(
      //         function (r) {
      //           state.modal.password.activities.data = r.data.activityList;
      //           state.modal.password.activities.loading = false;
      //         }
      //     ).catch(
      //     function () {
      //       state.modal.password.activities.data = [];
      //       state.modal.password.activities.loading = false;
      //     }
      // )
    }

    function deletePublicShare() {
      apiService.delete(
          ROUTES.publicShareSingle(),
          {
            shareId: state.modal.password.update.selected.node.public_share.id
          }
      )
          .then(
              (r) => {
                state.modal.password.share.loading = false;
                state.modal.password.share.publicShare.loading = false;
                state.modal.password.update.selected.node.public_share = null;
                state.__I_AM_THE_PASSWORD_FOR_PUBLIC_SHARE_BUT_BECAUSE_OF_GROWING_APP_ARCHITECTURE_I_CAN_NOT_BE_ON_MY_RIGHT_PLACE_AND_NEED_TO_GET_REFACTORED_SOON__ = '';
              }
          )

    }

    async function createPublicShare() {
      state.modal.password.update.showButtons = false;
      state.modal.password.share.publicShare.loading = true;

      const response = await apiService.get(
          ROUTES.getCredential(
              state.modal.password.update.selected.node.id
          )
      );

      if (response.status !== 200) {
        console.error('error - no 200')
        // todo error
        return;
      }
      const decrypted = keestashEncryptionService.decrypt(response.data.password);

      const otherResponse = await apiService.post(
          ROUTES.publicShareSingle(),
          {
            expire_date: state.modal.password.share.publicShare.expireDate,
            password: state.__I_AM_THE_PASSWORD_FOR_PUBLIC_SHARE_BUT_BECAUSE_OF_GROWING_APP_ARCHITECTURE_I_CAN_NOT_BE_ON_MY_RIGHT_PLACE_AND_NEED_TO_GET_REFACTORED_SOON__,
            acknowledged: true,
            node: {
              username: state.modal.password.update.form.userName,
              password: decrypted,
              id: state.modal.password.update.selected.node.id,
            },
          }
      );

      if (otherResponse.status !== 200) {
        console.error('error - no 200 -2 ')
        // todo error
        return;
      }
      state.modal.password.share.loading = false;
      state.modal.password.share.publicShare.loading = false;
      state.modal.password.update.selected.node.public_share = otherResponse.data.share;
      state.__I_AM_THE_PASSWORD_FOR_PUBLIC_SHARE_BUT_BECAUSE_OF_GROWING_APP_ARCHITECTURE_I_CAN_NOT_BE_ON_MY_RIGHT_PLACE_AND_NEED_TO_GET_REFACTORED_SOON__ = '';
    }

    function onGenerateShareClick() {
      if (state.modal.password.update.selected?.node?.public_share === null) {
        state.modal.password.share.publicShare.modal.isVisible = true;
        return;
      }
      onGenerateShareLinkAcknowledged();
    }

    function onGenerateShareLinkAcknowledged() {
      if (state.modal.password.share.publicShare.loading) {
        return;
      }

      state.modal.password.update.showButtons = false;
      state.modal.password.share.publicShare.loading = true;

      if (state.modal.password.update.selected?.node?.public_share !== null) {
        deletePublicShare();
        return;
      }
      createPublicShare();
      state.modal.password.share.publicShare.modal.isVisible = false;
    }

    function onPublicSharePasswordSet(e) {

      if (state.modal.password.update.selected?.node?.public_share === null) {
        return;
      }

      state.modal.password.update.showButtons = false;
      state.modal.password.share.publicShare.loading = true;

      apiService.post(
          ROUTES.publicShareSinglePassword(),
          {
            share_id: state.modal.password.update.selected?.node?.public_share.id,
            password: e.target.value
          }
      )
          .then(
              (r) => {
                state.modal.password.share.loading = false;
                state.modal.password.share.publicShare.loading = false;
              }
          )
    }

    function addAdditionalData() {
      if (state.modal.password.additionalData.add.state === STATE_INTERACTING) {
        return;
      }

      state.modal.password.additionalData.add.state = STATE_INTERACTING;
      state.modal.password.update.interacting = true;
      apiService.post(
          ROUTES.addAdditionalData(),
          {
            credentialId: state.modal.password.update.selected.node.id,
            key: state.modal.password.additionalData.add.data.key,
            value: keestashEncryptionService.encrypt(state.modal.password.additionalData.add.data.value)
          }
      ).then(
          function (r) {
            const data = r.data.data;
            const dataCount = data.length;

            state.modal.password.additionalData.add.state =
                dataCount === 0
                    ? STATE_INTERACTED_EMPTY
                    : STATE_INTERACTED_NON_EMPTY;

            state.modal.password.additionalData.retrieve.data.push(data);
            state.modal.password.additionalData.add.data.key = null;
            state.modal.password.additionalData.add.data.value = null;
            state.modal.password.update.interacting = false;
          }
      )
    }

    watch(
        () => state.modal.password.additionalData.retrieve.data,
        (n, o) => {
          console.log(n);
          state.modal.password.additionalData.retrieve.state =
              n.length > 0
                  ? STATE_INTERACTED_NON_EMPTY
                  : STATE_INTERACTED_EMPTY;

        },
        {deep: true}
    );

    function onChangeAttachment(event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      state.modal.password.attachment.add.data.value = event;

      if (state.modal.password.attachment.retrieve.state === STATE_INTERACTING) {
        return;
      }

      state.modal.password.attachment.retrieve.state = STATE_INTERACTING;
      state.modal.password.update.interacting = true;

      const formData = new FormData();
      formData.append('file', state.modal.password.attachment.add.data.value.target.files[0] || {});
      formData.append('node_id', state.modal.password.update.selected.node.id);
      formData.append('name', state.modal.password.attachment.add.data.key);

      apiService.post(
          ROUTES.addAttachment(),
          formData,
          {'Content-Type': 'multipart/form-data'}
      ).then(
          function (r) {
            state.modal.password.attachment.retrieve.state = STATE_INITIALIZED;
            state.modal.password.attachment.retrieve.data.push(r.data.files[0]);
            state.modal.password.attachment.add.data.key = null;
            state.modal.password.update.interacting = false;
            state.modal.password.attachment.add.data.value.target.value = null;
          }
      )
    }

    watch(
        () => state.modal.password.attachment.retrieve.data,
        (n, o) => {
          console.log(n);
          state.modal.password.attachment.retrieve.state =
              n.length > 0
                  ? STATE_INTERACTED_NON_EMPTY
                  : STATE_INTERACTED_EMPTY;

        },
        {deep: true}
    );


    function onAdditionalClick(id) {
      if (state.modal.password.additionalData.retrieve.retrieved.loading) {
        return;
      }
      state.modal.password.additionalData.retrieve.retrieved.loading = true;

      apiService.get(
          ROUTES.getAdditionalDataValue(id)
      )
          .then(
              function (r) {
                state.modal.password.additionalData.retrieve.retrieved.value = keestashEncryptionService.decrypt(r.data.value);
                state.modal.password.additionalData.retrieve.retrieved.loading = false;
              }
          )
    }

    function deleteAdditionalData(event, id) {
      if (state.modal.password.additionalData.remove.state === STATE_INTERACTING) {
        return;
      }

      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
      state.modal.password.update.interacting = true;
      state.modal.password.additionalData.remove.state = STATE_INTERACTING;
      apiService.delete(
          ROUTES.deleteAdditionalData(id)
      )
          .then(
              function (r) {
                state.modal.password.additionalData.retrieve.data =
                    state.modal.password.additionalData.retrieve.data.filter(
                        function (data) {
                          return data.id !== id;
                        }
                    );
                state.modal.password.additionalData.retrieve.retrieved.value = null;
                state.modal.password.additionalData.remove.state = STATE_INITIALIZED;
              }
          )
    }

    onBeforeUnmount(
        function () {
          state.loadEdgesAbortController.abort();
        }
    )
    onMounted(
        function () {
          loadEdges(
              appStorage.get('selected', CONFIG.NODE_ID_ROOT)
          );
          document.addEventListener(
              'onResultItemClick',
              function (event) {
                onEdgeClick(
                    {
                      node: event.detail.node
                    }
                )
              }
          );
          const d = new Date();
          d.setDate(d.getDate() + 3);
          state.modal.password.share.publicShare.expireDate = d;
        }
    );

    function getPublicShareInfo() {
      if (state.modal.password.update.selected === null) {
        return i18n.t('password.modal.password.share.publicShare.generateLink');
      }
      if (state.modal.password.update.selected.node === null) {
        return i18n.t('password.modal.password.share.publicShare.generateLink')
      }
      if (state.modal.password.update.selected.node.public_share === null) {
        return i18n.t('password.modal.password.share.publicShare.generateLink')
      }
      return i18n.t('password.modal.password.share.publicShare.linkExpireDate', {expireDate: formatDate(state.modal.password.update.selected.node.public_share.expire_ts.date)});
    }

    function copyLink() {
      if (state.modal.password.update.selected === null) {
        return null;
      }
      if (state.modal.password.update.selected.node === null) {
        return null;
      }
      navigator.clipboard.writeText(ROUTES.getPublicShareUrl(state.modal.password.update.selected.node.public_share.hash))
          .then(
              function () {
                console.log('clipboard successfully set');
                state.modal.password.update.interacting = false;
                state.modal.password.share.success.push('copied to clipboard');
                clearAlerts('share');
              }
          )
          .catch(
              (e) => {
                console.log(e)
                console.log('clipboard write failed');
                state.modal.password.update.interacting = false;
                state.modal.password.share.errors.push(e);
                clearAlerts('share');
              }
          )
    }

    function onGenerateShareLinkUnacknowledged() {
    }

    return {
      state: toRefs(state),
      loadEdges: loadEdges,
      apiService: apiService,
      appStorage: appStorage,
      onUsernameCopy: onUsernameCopy,
      showEmptyScreen: showEmptyScreen,
      createPassword: createPassword,
      updateCredential: updateCredential,
      createFolder: createFolder,
      onDelete: onDelete,
      onRename: onRename,
      renameNode: renameNode,
      onThreeDotsClick: onThreeDotsClick,
      search: search,
      showLoadingScreen: showLoadingScreen,
      onEyeClick: onEyeClick,
      onOpenLink: onOpenLink,
      onPasswordGeneratorShown: onPasswordGeneratorShown,
      onPasswordGeneratorAddShown: onPasswordGeneratorAddShown,
      generatePassword: generatePassword,
      useGeneratedPassword: useGeneratedPassword,
      onFocusNewPassword: onFocusNewPassword,
      onCopyClick: onCopyClick,
      copyPassword: copyPassword,
      clearUpdateModal: clearUpdateModal,
      clearAddModal: clearAddModal,
      destroyAddModal: destroyAddModal,
      onAddPassword: onAddPassword,
      onAddFolder: onAddFolder,
      addModal: addModal,
      renameModal: renameModal,
      redirectModal: redirectModal,
      deleteModal: deleteModal,
      regularShareModal: regularShareModal,
      addFolder: addFolder,
      updateModal: updateModal,
      deleteNode: deleteNode,
      clearAddFolder: clearAddFolder,
      destroyAddFolder: destroyAddFolder,
      onEdgeClick: onEdgeClick,
      getEntropy: getEntropy,
      getHealthDescription: getHealthDescription,
      getHealthIcon: getHealthIcon,
      formatDate: formatDate,
      formatFromNow: formatFromNow,
      startDragging: startDragging,
      endDragging: endDragging,
      drop: drop,
      dragover: dragover,
      dragleave: dragleave,
      redirect: redirect,
      clearPasswordGeneratorUpdate: clearPasswordGeneratorUpdate,
      clearPasswordGeneratorAdd: clearPasswordGeneratorAdd,
      setPasswordTimeout: setPasswordTimeout,
      onAdditionalDataClick: onAdditionalDataClick,
      onAttachmentClick: onAttachmentClick,
      onActivityClick: onActivityClick,
      onShareClick: onShareClick,
      addAdditionalData: addAdditionalData,
      onAdditionalClick: onAdditionalClick,
      deleteAdditionalData: deleteAdditionalData,
      deleteSingleAttachment: deleteSingleAttachment,
      onChangeAttachment: onChangeAttachment,
      getIconUrl: getIconUrl,
      getAttachmentLink: getAttachmentLink,
      onGenerateShareClick: onGenerateShareClick,
      getPublicShareInfo: getPublicShareInfo,
      copyLink: copyLink,
      onPublicSharePasswordSet: onPublicSharePasswordSet,
      onGenerateShareLinkAcknowledged: onGenerateShareLinkAcknowledged,
      onGenerateShareLinkUnacknowledged: onGenerateShareLinkUnacknowledged,
      keestashEncryptionService: keestashEncryptionService,
      onRegularShare: onRegularShare
    };
  }
}
</script>

<style scoped lang="scss">
.pwm-table-cell {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  cursor: pointer;
}

.password-row:hover td {
  background-color: #F5F5F5;
  border-bottom: 0;
}

#password-manager-table {
  max-height: 75vh;
  overflow-y: scroll;
  background-color: #F8F9FA;
}

#password-wrapper {
  box-sizing: border-box;
  border: 1px solid #E2E8F0;
  box-shadow: 0px 2px 5.5px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  padding: 20px;
  background-color: #F8F9FA;
  backdrop-filter: blur(21px);
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
}

#table-head {
  border-bottom: 2px #E2E8F0 solid !important;
}

.icon-image {
  width: 30px;
  color: white;
}

.icon-image-17rem {
  width: 1.7rem;
  color: white;
}

.__margin_bottom__11rem {
  margin-bottom: 1.1rem;
}

.pwm-properties-name-list {
  min-height: 40vh;
  max-height: 40vh;
  height: 40vh;
}

.modal-header {
  background-color: #081A51;
  color: white !important;
}

.modal-label {
  font-weight: 400;
  font-size: 1rem;
}

.k-modal-content {
  border: 0 !important;
  background-color: white !important;
}

.modal-input {
  padding: 0.7rem;
}

#my-icon {
  height: 1.2rem;
}

#forward {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.password-generator-enter-active,
.password-generator-add-enter-active,
{
  transition: all 0.6s ease-out;
}

.password-generator-leave-active,
.password-generator-add-leave-active {
  transition: all 0.3s ease-out;
}

.password-generator-enter-from,
.password-generator-leave-to,
.password-generator-add-enter-from,
.password-generator-add-leave-to,
{
  opacity: 0;
}

.generated-password {
  border-radius: 0.5rem;
  padding: 1rem;
}

tbody > tr:last-child {
  border: 0;
  border-bottom-color: #F8F9FA;
}

.button-border {
  border: 1px solid #ced4da;
}

@media (min-width: 576px) {
  .d-sm-header-group {
    display: table-header-group !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
}

@media (min-width: 768px) {
  .d-md-header-group {
    display: table-header-group !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
}

@media (min-width: 992px) {
  .d-lg-header-group {
    display: table-header-group !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-header-group {
    display: table-header-group !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
}

@media (min-width: 1400px) {
  .d-xxl-header-group {
    display: table-header-group !important;
  }
  .d-xxl-table-cell {
    display: table-cell !important;
  }
}
</style>
