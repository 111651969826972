<template>
  <li class="list-group-item clickable hovered" @click="this.$emit('onClick')">
    <slot/>
  </li>
</template>

<script>
export default {
  name: "ContextMenuItem"
}
</script>

<style lang="scss" scoped>
.hovered:hover {
  background-color: #F5F5F5;
}
</style>

